﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { first } from 'rxjs/operators';
import { HeaderComponent } from '../_partials/header/header.component';
// import { PivateHeaderComponent } from '../_partials/header/privateHeader.component';
// import { PublicHeaderComponent } from '../_partials/header/publicHeader.component';
import { FooterComponent } from '../_partials/footer/footer.component';
// import * as $ from 'jquery';
// import { environment } from '../../../environments/environment'
import * as bootstrap from 'bootstrap';

import { DataService } from '../../services/data.service';
@Component({ templateUrl: 'permanency.component.html',providers: [HeaderComponent,FooterComponent], })
export class PermanencyComponent implements OnInit {
    // siteurl=environment.siteurl;
    response:any='';
    content:any='';
    pageclass:any='';
    constructor(
        // private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router
        , private data: DataService
        ) {

        }

    ngOnInit() {
        this.route.data.subscribe(data => {
            console.log('data here');
            console.log(data);
            var paramid = this.route.snapshot.params.slug;
        var paramid2 = this.route.snapshot.params.slug1;
        // var slug='';
        // if(paramid=='' || paramid==null || typeof paramid=='undefined'){
        //     paramid='home';
        // }
        // slug=paramid;
        // if(paramid2!='' && paramid2!=null && typeof paramid2!='undefined'){
        //     slug=paramid+'/'+paramid2
        // }
        this.pageclass=data.slug;
        //console.log(paramid);
        this.data.pagecontent(data.slug).subscribe(res => {
            this.response=res;
            this.response=JSON.parse(this.response);
            this.content=this.response.content;
            // $(".collapse.show").each(function () {
            //     $(this).prev(".custom-tabs .card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
            // });
        
            // // Toggle plus minus icon on show hide of collapse element
            // $(".collapse").on('show.bs.collapse', function () {
            //     $(this).prev(".custom-tabs .card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
            // }).on('hide.bs.collapse', function () {
            //     $(this).prev(".custom-tabs .card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
            // });
            
    });
        });
        

    }

    // convenience getter for easy access to form fields
}
