﻿import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import * as bootstrap from 'bootstrap';
import { DataService } from '../../../services/data.service';
import { map, filter } from 'rxjs/operators'
declare function stopSession(): any;
declare var $: any;
@Component({ templateUrl: 'header.component.html', selector: 'app-header', })
export class HeaderComponent implements OnInit {
    ismember = false;
    profile_image = '';
    loaddata: any = [];
    logo = '';
    token_price = 0;
    response: any = '';
    header: any = '';
    values:any;
    constructor(private route: ActivatedRoute,
        private router: Router
        , private data: DataService
    ) {
    }

    ngOnInit() {
        // navigator.serviceWorker.getRegistrations().then(registrations => {
        //     console.log('registrations');
        //     console.log(registrations);
        // });
        if (localStorage.getItem('contactform_data')) {
            console.log('contactform_data');
            // Set keys and values for voting for your favorite article	var names = ['first', 'last', 'article'];
                this.values = localStorage.getItem('contactform_data');
                this.values=JSON.parse(this.values);
                console.log(this.values);
                var data = { name: this.values.name, email: this.values.email, phone: this.values.phone, zipcode: this.values.zipcode, interests: this.values.interests, msg: this.values.msg };
                        this.data.submit_form(data).subscribe(res => {
                            localStorage.removeItem('contactform_data');
                            // this.response=res;
                            // if(this.response.status){
                            //     alert(this.response.msg);
                            //     window.location.reload();
                            // }
                        });
               
            // // Form to append inputs to
            // var form = $("#offlinePostForm"); 
            // // Create and append inputs
            // for (var i = 0; i < names.length; i++) {
            //     var input = '<input type="hidden" name="' 
            //     + names[i] + '" value="' 
            //     + values[i] + '">';
            //     $(input).appendTo(form);
            // }
            // // Clear newPost from localStorage and submit the form
            // localStorage.removeItem('newPost');
            // form.submit();
            }


        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
            $.getScript('https://static.addtoany.com/menu/page.js');
        });
        this.route.data.subscribe(data => {
            console.log('data here');
            console.log(data);
            var paramid = this.route.snapshot.params.slug;
            var paramid2 = this.route.snapshot.params.slug1;
            // var slug='';
            // if(paramid=='' || paramid==null || typeof paramid=='undefined'){
            //     paramid='home';
            // }
            // slug=paramid;
            // if(paramid2!='' && paramid2!=null && typeof paramid2!='undefined'){
            //     slug=paramid+'/'+paramid2
            // }
            // this.pageclass=data.slug;
            //console.log(paramid);
            this.data.getHeader(data.slug).subscribe(res => {
                this.response = res;
                this.response = JSON.parse(this.response);
                this.header = this.response.header;

            });
        });


    }
    clicklink(e) {
        let link = e.target.attributes[0].value.trim();
        let name = e.target.attributes[0].name.trim();
        // console.log(e.target.attributes[0].name);
        // console.log(e.target.attributes[0].value);
        if (link != '' && link != null && typeof link != 'undefined' && name == 'routerlink') {
            this.router.navigate([e.target.attributes[0].value]);
        }

    }
}