﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { first } from 'rxjs/operators';
import { HeaderComponent } from '../_partials/header/header.component';
// import { PivateHeaderComponent } from '../_partials/header/privateHeader.component';
// import { PublicHeaderComponent } from '../_partials/header/publicHeader.component';
import { FooterComponent } from '../_partials/footer/footer.component';
// import * as $ from 'jquery';
// import { environment } from '../../../environments/environment'
import * as bootstrap from 'bootstrap';

import { DataService } from '../../services/data.service';
@Component({ templateUrl: 'alert.component.html', providers: [HeaderComponent, FooterComponent], })
export class AlertComponent implements OnInit {
    // siteurl=environment.siteurl;
    response: any = '';
    content: any = '';
    pageclass: any = '';
    alertslist;
    msgcontent='';
    constructor(
        // private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router
        , private data: DataService
    ) {

    }

    ngOnInit() {
        var paramid = this.route.snapshot.params.slug;
        var paramid2 = this.route.snapshot.params.slug1;
        this.pageclass = 'permanency';
        //console.log(paramid);
       // var token = localStorage.getItem('fcm_token');
       // if (token != '' && token != null && typeof token != 'undefined') {
            this.data.listalerts().subscribe(res => {
                this.response = res;
                let evilResponseProps = Object.values(this.response);
                let goodResponse = [];
                // Step 3. Iterate throw all keys.
                for (var prop in evilResponseProps) {
                    goodResponse.push(evilResponseProps[prop]);
                }
                this.alertslist=goodResponse;
                console.log(this.alertslist);
            });
        // } else {
        //     alert('No notifications found');
        // }


    }

    // convenience getter for easy access to form fields
}
