/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../pipes/keep-html.pipe";
import * as i2 from "@angular/platform-browser";
import * as i3 from "./footer.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../services/data.service";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "footer", [["class", "footer"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clicklink($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵpid(0, i1.EscapeHtmlPipe, [i2.DomSanitizer]), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "a2a_kit a2a_kit_size_32 a2a_default_style"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "a", [["class", "a2a_button_facebook"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "a", [["class", "a2a_button_twitter"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform(_co.footer)); _ck(_v, 0, 0, currVal_0); }); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 114688, null, 0, i3.FooterComponent, [i4.ActivatedRoute, i4.Router, i5.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i0.ɵccf("app-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
