import { RouterModule } from '@angular/router';
// import { LoginComponent } from './components/login';
// import { LogoutComponent } from './components/login/logout.component';
import { IndexComponent } from './components/public/index.component';
import { PermanencyComponent } from './components/public/permanency.component';
import { ContactComponent } from './components/public/contact.component';
import { AlertComponent } from './components/public/alert.component';
var ɵ0 = { slug: 'foster' }, ɵ1 = { slug: 'permanency' }, ɵ2 = { slug: 'permanency' }, ɵ3 = { slug: 'how-can-i-help' }, ɵ4 = { slug: 'resources' }, ɵ5 = { slug: 'resources/applicant-resources' }, ɵ6 = { slug: 'resources/community-resources' }, ɵ7 = { slug: 'resources/foster-youth-resources' }, ɵ8 = { slug: 'resources/parent-resources' }, ɵ9 = { slug: 'home' }, ɵ10 = { slug: 'contact' }, ɵ11 = { slug: 'home' };
// import { ContentComponent } from './components/public/content.component';
// import { TermsComponent } from './components/public/terms.component';
// import { PrivacyComponent } from './components/public/privacy.component';
// import { RegisterComponent } from './components/register';
// import { GetTokensComponent } from './components/private/gettokens.component';
// import { FollowingComponent } from './components/private/following.component';
// import { LandingComponent } from './components/public/landing.component';
// import { LiveComponent } from './components/private/live.component';
// import { LiveComponent2 } from './components/private/live.component2';
// import { MentorDetailsComponent } from './components/private/mentordetails.component';
// import { MyProfileComponent } from './components/private/myprofile.component';
// import { ProfileEditComponent } from './components/private/profileedit.component';
// import { ForgotComponent } from './components/public/forgot.component';
// import { TheaterComponent } from './components/private/theater.component';
// import { AllMentorsComponent } from './components/private/allmentors.component';
// import { AuthGuard } from './_guards'; 
var appRoutes = [
    { path: 'foster', component: IndexComponent, data: ɵ0 },
    { path: 'alerts', component: AlertComponent, data: ɵ1 },
    { path: 'permanency', component: PermanencyComponent, data: ɵ2 },
    { path: 'how-can-i-help', component: IndexComponent, data: ɵ3 },
    { path: 'resources', component: IndexComponent, data: ɵ4 },
    // { path: 'permanency', component: IndexComponent,data:{slug:'permanency'}  },
    { path: 'applicant-resources', component: IndexComponent, data: ɵ5 },
    { path: 'community-resources', component: IndexComponent, data: ɵ6 },
    { path: 'foster-youth-resources', component: IndexComponent, data: ɵ7 },
    { path: 'parent-resources', component: IndexComponent, data: ɵ8 },
    { path: 'home', component: IndexComponent, data: ɵ9 },
    { path: 'contact', component: ContactComponent, data: ɵ10 },
    { path: '', component: IndexComponent, data: ɵ11 },
    // otherwise redirect to home
    { path: '*', redirectTo: 'home' }
];
export var routing = RouterModule.forRoot(appRoutes);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
