﻿import { Routes, RouterModule } from '@angular/router';

// import { LoginComponent } from './components/login';
// import { LogoutComponent } from './components/login/logout.component';
import { IndexComponent } from './components/public/index.component';
import { PermanencyComponent } from './components/public/permanency.component';
import { ContactComponent } from './components/public/contact.component';  
import { AlertComponent } from './components/public/alert.component';  
// import { ContentComponent } from './components/public/content.component';
// import { TermsComponent } from './components/public/terms.component';
// import { PrivacyComponent } from './components/public/privacy.component';
// import { RegisterComponent } from './components/register';
// import { GetTokensComponent } from './components/private/gettokens.component';
// import { FollowingComponent } from './components/private/following.component';
// import { LandingComponent } from './components/public/landing.component';
// import { LiveComponent } from './components/private/live.component';
// import { LiveComponent2 } from './components/private/live.component2';
// import { MentorDetailsComponent } from './components/private/mentordetails.component';
// import { MyProfileComponent } from './components/private/myprofile.component';
// import { ProfileEditComponent } from './components/private/profileedit.component';
// import { ForgotComponent } from './components/public/forgot.component';
// import { TheaterComponent } from './components/private/theater.component';
// import { AllMentorsComponent } from './components/private/allmentors.component';
// import { AuthGuard } from './_guards'; 

const appRoutes: Routes = [
    { path: 'foster', component: IndexComponent,data:{slug:'foster'}  },
    { path: 'alerts', component: AlertComponent,data:{slug:'permanency'}  },
    { path: 'permanency', component: PermanencyComponent,data:{slug:'permanency'}  },
    { path: 'how-can-i-help', component: IndexComponent,data:{slug:'how-can-i-help'}  },
    { path: 'resources', component: IndexComponent,data:{slug:'resources'}  },
    // { path: 'permanency', component: IndexComponent,data:{slug:'permanency'}  },
    { path: 'applicant-resources', component: IndexComponent,data:{slug:'resources/applicant-resources'}  },
    { path: 'community-resources', component: IndexComponent,data:{slug:'resources/community-resources'}  },
    { path: 'foster-youth-resources', component: IndexComponent,data:{slug: 'resources/foster-youth-resources'}  },
    { path: 'parent-resources', component: IndexComponent,data:{slug:'resources/parent-resources'}  },
    { path: 'home', component: IndexComponent,data:{slug:'home'}  },
    { path: 'contact', component: ContactComponent,data:{slug:'contact'}  },
    { path: '', component: IndexComponent,data:{slug:'home'}  },

    // otherwise redirect to home
    { path: '*', redirectTo: 'home' }
];

export const routing = RouterModule.forRoot(appRoutes);