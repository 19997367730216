import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DataService } from './services/data.service';
import { IndexComponent } from './components/public/index.component';
import { MessagingService } from "./services/messaging.service";

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  message;
  update: boolean = false;

  constructor(updates: SwUpdate, private data: DataService,
            private messagingService: MessagingService
    ) {
    updates.available.subscribe(event => {

      //this.update = true;
      updates.activateUpdate().then(() => document.location.reload());

    })
  }

  ngOnInit() {
    this.messagingService.requestPermission();
            this.messagingService.receiveMessage();
            this.message = this.messagingService.currentMessage;
            console.log(this.message);
    // this.data.gimmeJokes().subscribe(res => {
    //   this.res=res;
    //   console.log(this.res);
    //   this.pwa_title=this.res.title.rendered;
    //   this.content=this.res.content.rendered;
    //   //this.joke = res;
    // });
    // this.data.menu().subscribe(res => {
    //   this.res=res;
    //   console.log(this.res);
    //   this.menu=this.res.html;
    //   //this.joke = res;
    // });
  }

}
