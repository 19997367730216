﻿<app-header></app-header>
<main class="main-content-area {{pageclass}}">
    <div class="banner-area"
        style="background-image: url('https://devf2fmc.seemysite.us/wp-content/uploads/2020/06/banner-img.png');">
        <div class="container">
            <h2>Alerts</h2>
        </div>

    </div>
    <div class="container" style="padding-bottom: 20px;">
        <h3>Notifications List</h3>
        <table class="table table-striped table-responsive">
            <thead>
              <tr class="d-flex">
                <th class="col-md-2 title-col">Title</th>
                <th class="col-md-8 msg-col col-sm-10">Message</th>
                <th class="col-md-2 col-sm-2 date-col">Date</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let alert of alertslist" class="d-flex">
                    <td class="col-md-2 title-col">
                        {{alert.title}}
                    </td>
                    <td class="col-md-8 msg-col col-sm-10" [innerHtml]="'<span class=\'phonedate\'>'+alert.date+'-</span>'+alert.content | keepHtml">
                    </td>
                    <td class="col-md-2 col-sm-2 date-col">
                        {{alert.date}}
                    </td>
                </tr>
            </tbody>
          </table>
    </div>

</main>
<app-footer></app-footer>