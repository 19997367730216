﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { first } from 'rxjs/operators';
import { HeaderComponent } from '../_partials/header/header.component';
// import { PivateHeaderComponent } from '../_partials/header/privateHeader.component';
// import { PublicHeaderComponent } from '../_partials/header/publicHeader.component';
import { FooterComponent } from '../_partials/footer/footer.component';
// import * as $ from 'jquery';
// import { environment } from '../../../environments/environment'
import * as bootstrap from 'bootstrap';
declare var $: any;
declare global {
    interface Window {
        clients: any;
    }
}

import { DataService } from '../../services/data.service';
@Component({ templateUrl: 'contact.component.html', providers: [HeaderComponent, FooterComponent], })
export class ContactComponent implements OnInit {
    // siteurl=environment.siteurl;
    response: any = '';
    content: any = '';
    pageclass: any = '';
    constructor(
        // private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router
        , private data: DataService
    ) {

    }

    ngOnInit() {
        var thisobj = this;
        // console.log('contactform_data');
        // console.log(localStorage.getItem('contactform_data'));

        this.route.data.subscribe(data => {
            console.log('data here');
            console.log(data);
            var paramid = this.route.snapshot.params.slug;
            var paramid2 = this.route.snapshot.params.slug1;
            // var slug='';
            // if(paramid=='' || paramid==null || typeof paramid=='undefined'){
            //     paramid='home';
            // }
            // slug=paramid;
            // if(paramid2!='' && paramid2!=null && typeof paramid2!='undefined'){
            //     slug=paramid+'/'+paramid2
            // }
            this.pageclass = data.slug;
            //console.log(paramid);
            this.data.pagecontent(data.slug).subscribe(res => {
                this.response = res;
                this.response = JSON.parse(this.response);
                this.content = this.response.content;
            });
        });
        var formdata = this.data;
        $(document).on('click', '.wpcf7-submit', function (e) {
            e.preventDefault();
            //thisobj.checkoffline(e);
            var name = $('input[name="your-name"]').val();
            var email = $('input[name="your-email"]').val();
            var phone = $('input[name="phone"]').val();
            var message = $('textarea[name="your-message"]').val();
            var zipcode = $('input[name="zipcode"]').val();
            var interests = [];
            $.each($("input[name='interests[]']:checked"), function () {
                interests.push($(this).val());
            });
            if(name=='' || email=='' || phone=='' || message=='' || zipcode=='' || interests.length==0){
                alert('Please fill all fields');
                return false;
            }
            
            var data = { name: name, email: email, phone: phone, zipcode: zipcode, interests: interests, msg: message };
            $(this).text('Processing..');
            $(this).attr('disabled',true);
            formdata.submit_form(data).subscribe(res => {
                this.response=res;
                if(this.response.status){
                    alert(this.response.msg);
                    window.location.reload();
                }
            }, error => {
                thisobj.storeOffline(data);
                alert("Your request unnable to submit because page is offline. It will be sent once you are back online.");
            });
        });

    }
    storeOffline(data) {
        // var newPost = ""; // Inputted values
        // // Iterate through the inputs
        // $("input").each(function() {
        //     newPost += $(this).val() + ",";
        // });
        // // Get rid of the last comma
        // newPost = newPost.substr(0, newPost.length - 1);
        // // Store the data
        localStorage.setItem('contactform_data',JSON.stringify(data));
    }

    // convenience getter for easy access to form fields
}
