import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DataService = /** @class */ (function () {
    function DataService(http) {
        this.http = http;
    }
    DataService.prototype.gimmeJokes = function () {
        return this.http.get('https://devf2fmc.seemysite.us/wp-json/wp/v2/app_pages/449');
    };
    DataService.prototype.posytapp = function (fname, lname) {
        return this.http.post('https://devf2fmc.seemysite.us/wp-json/custom/posttapp', { fname: fname, lname: lname });
    };
    DataService.prototype.menu = function () {
        return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/pwa_menu');
    };
    DataService.prototype.getHeader = function (slug) {
        return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/pwa_header?slug=' + slug);
    };
    DataService.prototype.getFooter = function (token) {
        return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/pwa_footer?token=' + token);
    };
    DataService.prototype.pagecontent = function (slug) {
        return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/pwa_page_content?slug=' + slug);
    };
    DataService.prototype.listalerts = function () {
        console.log('listalerts');
        return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/list_app_notification');
    };
    DataService.prototype.noti_status = function (token) {
        console.log('listalerts');
        return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/noti_status?token=' + token);
    };
    DataService.prototype.submit_form = function (data) {
        return this.http.post('https://devf2fmc.seemysite.us/wp-json/custom/submit_form', data);
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(i0.inject(i1.HttpClient)); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
