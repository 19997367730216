import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routing }        from './app.routing';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { HttpClientModule } from '@angular/common/http';
import { DataService } from './services/data.service';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { FormsModule } from '@angular/forms';
import { IndexComponent } from './components/public/index.component';
import { PermanencyComponent } from './components/public/permanency.component';
import { AlertComponent } from './components/public/alert.component';
import { ContactComponent } from './components/public/contact.component';
import { HeaderComponent } from './components/_partials/header/header.component';
import { FooterComponent } from './components/_partials/footer/footer.component';

// firebase
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { MessagingService } from "./services/messaging.service";
import { AsyncPipe } from "../../node_modules/@angular/common";



import * as $ from 'jquery';


@NgModule({
  declarations: [
    AppComponent,
    EscapeHtmlPipe,
    IndexComponent,
    HeaderComponent,
    FooterComponent,
    PermanencyComponent,
    ContactComponent,
    AlertComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    routing,
    AngularFireDatabaseModule,
      AngularFireAuthModule,
      AngularFireMessagingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [DataService,MessagingService,AsyncPipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
