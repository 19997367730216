﻿<app-header></app-header>
        <main class="main-content-area {{pageclass}}" (click) = "clicklinks($event)"  [innerHtml]="content | keepHtml">
            <!-- <div class="container">
                <div class="homepage-services">

                    <a href="#" class="service-link"><img src="assets/images/blue-icon.png" class="icon-service-link">Foster</a>
                    <a href="#" class="service-link"><img src="assets/images/pink-icon.png" class="icon-service-link">How I Can Help</a>
                    <a href="#" class="service-link"><img src="assets/images/red-icon.png" class="icon-service-link">Resources</a>
                    <a href="#" class="service-link"><img src="assets/images/green-icon.png" class="icon-service-link">Permanency</a>
                    <a href="#" class="service-link"><img src="assets/images/orange-icon.png" class="icon-service-link">Contact</a>

                </div>
            </div> -->
        </main>
<app-footer></app-footer>