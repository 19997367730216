import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import * as bootstrap from 'bootstrap';
declare var $: any;
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  clientoken = '';
  res: any;
  constructor(private angularFireMessaging: AngularFireMessaging, private http: HttpClient) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.clientoken = token;
        localStorage.setItem('fcm_token', this.clientoken);
        //   this.listalerts(this.clientoken).subscribe(res => {
        //     console.log(res);
        //  });
        console.log('token: '+token);
        this.saveToken(token).subscribe(res => {
          console.log(res);
        });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.res = payload;
        // this.savealert(this.clientoken, this.res.notification.title, this.res.notification.body).subscribe(res => {
        //   console.log(res);
        // });
        $('.foster.alert').removeClass('read');
        $('.foster.alert').addClass('unread');
        console.log(" hellooww  hawarrr new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
  savealert(token, title, content) {
    console.log('savealert');
    return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/create_app_notification?token=' + token + '&title=' + title + '&content=' + content);
  }
  saveToken(token) {
    console.log('savetoken');
    return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/save_token?token=' + token);
  }

}