﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { setInterval } from 'timers';
import { setTimeout } from 'timers';
import * as bootstrap from 'bootstrap';
declare var $: any;
@Component({ templateUrl: 'footer.component.html', selector: 'app-footer', })
export class FooterComponent implements OnInit {
    response: any = '';
    footer: any = '';


    constructor(
        // private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private data: DataService
    ) {
    }

    ngOnInit() {
        this.route.data.subscribe(data => {
            console.log('data here');
            console.log(data);
            var paramid = this.route.snapshot.params.slug;
            var paramid2 = this.route.snapshot.params.slug1;
            var token = localStorage.getItem('fcm_token');
            this.data.getFooter(token).subscribe(res => {
                this.response = res;
                this.response = JSON.parse(this.response);
                this.footer = this.response.footer;
                if (token != '' && token != null && typeof token != 'undefined') {
                    this.response = res;
                    this.data.noti_status(token).subscribe(res => {
                        this.response = res;
                        console.log(this.response.status);
                        // $('.foster.alert').removeClass('read').removeClass('unread');
                        $('.foster.alert').addClass(this.response.status);
                    });
                }

            });
        });


    }
    clicklink(e) {
        let link = e.target.attributes[0].value.trim();
        let name = e.target.attributes[0].name.trim();
        // console.log(e.target.attributes[0].name);
        // console.log(e.target.attributes[0].value);
        if (link != '' && link != null && typeof link != 'undefined' && name == 'routerlink') {
            this.router.navigate([e.target.attributes[0].value]);
        }

    }
}