import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  gimmeJokes() {
    return this.http.get('https://devf2fmc.seemysite.us/wp-json/wp/v2/app_pages/449');
  }
  posytapp(fname, lname) {
    return this.http.post('https://devf2fmc.seemysite.us/wp-json/custom/posttapp',{fname:fname,lname:lname});
  }
  menu() {
    return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/pwa_menu');
  }
  getHeader(slug) {
    return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/pwa_header?slug='+slug);
  }
  getFooter(token) {
    return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/pwa_footer?token='+token);
  }
  pagecontent(slug) {
    return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/pwa_page_content?slug='+slug);
  }
  listalerts() {
    console.log('listalerts');
    return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/list_app_notification');
  }
  noti_status(token) {
    console.log('listalerts');
    return this.http.get('https://devf2fmc.seemysite.us/wp-json/custom/noti_status?token='+token);
  }
  submit_form(data){
    return this.http.post('https://devf2fmc.seemysite.us/wp-json/custom/submit_form',data);
  }
}
