/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../_partials/header/header.component.ngfactory";
import * as i2 from "../_partials/header/header.component";
import * as i3 from "@angular/router";
import * as i4 from "../../services/data.service";
import * as i5 from "../../pipes/keep-html.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../_partials/footer/footer.component.ngfactory";
import * as i8 from "../_partials/footer/footer.component";
import * as i9 from "./contact.component";
var styles_ContactComponent = [];
var RenderType_ContactComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContactComponent, data: {} });
export { RenderType_ContactComponent as RenderType_ContactComponent };
export function View_ContactComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.HeaderComponent, [i3.ActivatedRoute, i3.Router, i4.DataService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "main", [], [[8, "className", 0], [8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(0, i5.EscapeHtmlPipe, [i6.DomSanitizer]), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i0.ɵdid(5, 114688, null, 0, i8.FooterComponent, [i3.ActivatedRoute, i3.Router, i4.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 5, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "main-content-area ", _co.pageclass, ""); var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform(_co.content)); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_ContactComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ng-component", [], null, null, null, View_ContactComponent_0, RenderType_ContactComponent)), i0.ɵprd(4608, null, i2.HeaderComponent, i2.HeaderComponent, [i3.ActivatedRoute, i3.Router, i4.DataService]), i0.ɵprd(4608, null, i8.FooterComponent, i8.FooterComponent, [i3.ActivatedRoute, i3.Router, i4.DataService]), i0.ɵdid(3, 114688, null, 0, i9.ContactComponent, [i3.ActivatedRoute, i3.Router, i4.DataService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ContactComponentNgFactory = i0.ɵccf("ng-component", i9.ContactComponent, View_ContactComponent_Host_0, {}, {}, []);
export { ContactComponentNgFactory as ContactComponentNgFactory };
